.sidebar{
    flex: 1.5;
    height: calc(100vh - 60px);
    background:rgba(1, 35, 138, 0.952);
    top:60px;
    position: sticky;
    border-right: 1px solid #c2c2c2;
    color: #8b8b8b;
    padding: 10px;
    display: flex;
    flex-direction: column;
}
.sidebarMenu{
    margin-bottom: 10px;
}
.sidebarH3{
    font-size: 14px;
    color: #fafafa;
}
.sidebarH2{
    font-size: 15px;
    color: rgb(15, 15, 15);
    padding: 10px 0;
}

.sidebarLink{
    padding: 5px;
    cursor: pointer;
    border-radius: 10px;
    color: #494949;
    font-size: 16px;
}

.sidebarLi.active, .sidebarLi:hover{
    background: rgba(196, 24, 24, 0.082);
}
.sidebarIcon{
    font-size: 20px !important;
}


@media screen and (max-width: 414px){
    .sidebar{
        position: absolute;
        top: 0;
        left: 0;
        width: 200px;
        height: 100%;
        transform: translateX(-100%);
        transition: transform 0.3s;
        z-index: 999;
    }
    .visible{
        z-index: 999;
        position: fixed;
        transform: translateX(0);
        z-index: 999;
    }
}

@media screen and (max-width: 568px){
    .sidebar{
        position: absolute;
        top: 0;
        left: 0;
        width: 200px;
        height: 100%;
        transform: translateX(-100%);
        transition: transform 0.3s;
        z-index: 999;
    }
    .visible{
        z-index: 999;
        position: fixed;
        transform: translateX(0);
        z-index: 999;
    }
}